

export const sendVKPixelEvent = (goal, params) => {
  if (typeof window === "undefined" || !window._tmr) return;

  const { product_id, value } = params;

  window._tmr.push({
    type: "reachGoal",
    id: 3542076,
    value: value ?? 0, // Если value не передан, ставим 0
    goal,
    params: {
      product_id,
    },
  });
};
